<template>
    <div class="review-my">
        <list :apiList="apiList" :fs="reviewFields" :showAction="false" api="reviews" title="Reviews"></list>
    </div>
</template>

<script>
import List from "@/components/List";

import { reviewFields } from "@/config";
import { mapGetters } from "vuex";
export default {
    components: {
        List
    },
    data() {
        return {
            reviewFields: reviewFields.filter(field => !/active/.test(field.data))
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        apiList() {
            return `reviews?user_id=${this.user._id}`;
        },
        test() {
            return 222333;
        }
    },
    created() {}
};
</script>
<style lang="less">
.review-my {
    .md-table-row {
        height: auto;
        // .md-table-head,
        // .md-table-cell {
        //     height: auto;

        //     &:nth-child(1) {
        //         width: 200px;
        //         white-space: normal;
        //     }
        //     &:nth-child(5) {
        //         width: 500px;
        //         white-space: normal;
        //     }
        //     &:nth-child(7) {
        //         width: 130px;
        //         // white-space: normal;
        //     }
        // }
    }
}
</style>